// Packages
import styled from 'styled-components'

interface IndicatorContent {
  usage: number
  objective: number
  width?: number
}

interface Icon {
  finished: boolean
}

const getProgressBackgroundColor = (
  usage: number,
  objective: number
): string => {
  if (usage < objective / 2) {
    return '#ff6d6d'
  } else if (usage >= objective / 2 && usage < objective) {
    return '#e9cd32'
  } else {
    return '#07a55e'
  }
}

const getProgressMargin = (usage: number): string => {
  if (usage > 94) {
    return 'calc(94% - 12px);'
  } else {
    return `calc(${usage}% - 10px);`
  }
}

export const IndicatorContent = styled.div<IndicatorContent>`
  width: ${(props) => (props.width ? props.width : '90')}%;
  height: 110px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #0a2240;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? '90' : '45')}%;
  }
  .ant-progress {
    width: 70%;
    margin: 0 auto;
    margin-top: 8px;
    .ant-progress-outer {
      display: contents;
    }
    .ant-progress-inner {
      height: 16px;
      overflow: visible;
      background-image: linear-gradient(
        to right,
        rgb(255, 0, 0) 0%,
        rgb(230, 233, 57) 50%,
        rgb(0, 163, 94) 100%
      );
      .ant-progress-bg {
        height: 22px !important;
        margin-top: -3px;
        background-color: #fff;
        width: 10px !important;
        margin-left: ${(props) => getProgressMargin(props.usage)}
        :before {
          content: '${(props) => props.usage}%';
          color: #fff;
          margin-left: -4px;
          transform: translateY(-23px);
          display: block;
          font-weight: bold;
          display: inline-table;
        }
      }
    }
    .ant-progress-text {
      width: 35px;
      height: 35px;
      background-color: ${(props) =>
        getProgressBackgroundColor(props.usage, props.objective)};
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      float: right;
      transform: translateY(-27px);
    }
  }
`

export const StyledIndicatorHeader = styled.h1`
  color: #fff;
  margin-top: 6px;
  margin-left: 16px;
  font-size: 16px;
  margin-bottom: 10px;
`

export const StyledIndicatorContentProgress = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

export const StyledIndicatorDays = styled.div`
  width: 28%;
  margin-left: 2%;
  color: #fff;
  text-align: center;
  display: grid;
  transform: translateY(-27px);
  span {
    font-size: 14px;
    &:first-child {
      font-weight: bold;
    }
  }
`

export const StyledPopup = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(16, 53, 110, 0.89);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`

export const ClosePopup = styled.div`
  position: fixed;
  float: right;
  right: 8px;
  top: 4px;
  font-size: 21px;
`

export const Content = styled.div`
  height: fit-content;
  width: 300px;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 0.5em;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  padding: 40px 0;
  text-align: center;
  @media (min-width: 768px) {
    width: 623px;
  }
`

export const Icon = styled.div<Icon>`
  span {
    font-size: 52px;
    color: ${(props) => (props.finished ? '#009632' : '#ff8c07')};
  }
`

export const Title = styled.p`
  color: #0b4793;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-top: 19px;
  margin-bottom: 12px;
`

export const Label = styled.div`
  font-size: 16px;
`

export const Text = styled.p`
  width: 270px;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 16px;
`

export const TextReasignation = styled.p`
  text-align: center;
  color: #ffc610;
  font-weight: 300;
`
