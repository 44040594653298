// Packages
import styled from 'styled-components'

interface IndicatorContent {
  usage: number
}

const getTexColorProgresColor = (usage: number): string => {
  if (usage < 50) {
    return '#FFF'
  } else {
    return '#000'
  }
}

const getProgressBackgroundColor = (usage: number): string => {
  if (usage < 50) {
    return '#ff6d6d'
  } else if (usage >= 50 && usage < 75) {
    return '#e9cd32'
  } else {
    return '#07a55e'
  }
}

const getProgressMargin = (usage: number): string => {
  if (usage > 90) {
    return 'calc(90% - 5px);'
  } else {
    return `calc(${usage}% - 5px);`
  }
}

export const SelloutSellInContent = styled.div<IndicatorContent>`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    width: 45%;
  }

  .ant-progress {
    width: 90%;

    .ant-progress-text {
      display: none;
    }

    .ant-progress-inner {
      .ant-progress-bg {
        width: 35px !important;
        height: 35px !important;
        margin-top: -10px !important;
        margin-left: ${(props) => getProgressMargin(props.usage)} !important;
        background-color: ${(props) => getProgressBackgroundColor(props.usage)};
      }

      .ant-progress-bg:before {
        color: ${(props) => getTexColorProgresColor(props.usage)};
        transform: translate(6px, 6px) !important;
        margin-left: 0;
      }
    }
  }
`

export const StyledCardMetricContent = styled.div`
  width: 28%;
  margin-left: 2%;
  margin-top: 15px;
`

export const StyledMetricCard = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #0a2240;
  color: #fff;
  display: grid;

  &:first-child {
    margin-bottom: 8px;
  }

  small {
    font-size: 12px;
    margin-top: 6px;
    margin-left: 10px;
  }

  span {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
`
