// Packages
import React from 'react'
import { Progress } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

// Styled Components
import {
  IndicatorContent,
  StyledIndicatorHeader,
  StyledIndicatorContentProgress,
  StyledIndicatorDays,
  TextReasignation
} from './styledComponents'

// Components
import UsabiltyPopup, { UsabiltyPopupProps } from './usabiltyPopup'

export interface UsablityMetricsProps {
  daysUsedPercent?: number
  daysUsed?: number
  message?: string
  finished?: boolean
  objective?: number
  posUsabilityPopup: boolean
  usabiltyPopupProps: UsabiltyPopupProps
  hiddeUsabilityMetrics: boolean
  progressPercent: number
}

const UsablityMetrics: React.FC<UsablityMetricsProps> = ({
  daysUsedPercent,
  daysUsed,
  message,
  finished,
  objective,
  posUsabilityPopup,
  usabiltyPopupProps,
  hiddeUsabilityMetrics,
  progressPercent
}) => {
  return (
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    <>
      <IndicatorContent usage={daysUsedPercent!} objective={objective!}>
        <StyledIndicatorHeader>
          Usabilidad del POS{' '}
          <InfoCircleFilled onClick={usabiltyPopupProps.setPOSUsabilityPopup} />
        </StyledIndicatorHeader>
        <StyledIndicatorContentProgress>
          {!hiddeUsabilityMetrics ? (
            <React.Fragment>
              <Progress
                percent={progressPercent}
                format={(percent) => percent}
              />
              <StyledIndicatorDays>
                <span>{daysUsed}</span>
                <span>Días de uso este mes</span>
              </StyledIndicatorDays>
            </React.Fragment>
          ) : (
            <TextReasignation>
              Tus métricas comenzarán a <br />
              mostrarse el siguiente mes
            </TextReasignation>
          )}
        </StyledIndicatorContentProgress>
      </IndicatorContent>
      {posUsabilityPopup && (
        <UsabiltyPopup
          {...usabiltyPopupProps}
          message={message}
          finished={finished}
        />
      )}
    </>
  )
}

export default UsablityMetrics
