// Packages
import React from 'react'
import { Col } from 'antd'

// Styled Components
import { StyledUserDashboard } from './styledComponents'

// Components
import IconComponent from '../Icon'

// Assets
import AvatarIcon from '../../data/assets/icons/avatar.svg'

type props = {
  userName: string
}

const UserDashboard: React.FC<props> = ({ userName }) => (
  <StyledUserDashboard justify="space-between">
    <Col>
      <span>Bienvenid@</span>
      <h1>{userName}</h1>
    </Col>
    <Col>
      <IconComponent icon={AvatarIcon} width={46} height={46} />
    </Col>
  </StyledUserDashboard>
)

export default UserDashboard
