/* istanbul ignore file */
// Types
import React from 'react'
import { KpisMetricsTypes, MetricsTypes } from '.'
import Amount from '../Amount'

export const setValueMetrics = (
  metrics: MetricsTypes,
  kpisMetrics: KpisMetricsTypes,
  existData: boolean
): void => {
  if (existData) {
    switch (metrics.label) {
      case 'Producto top':
        metrics.value = kpisMetrics.name
        break
      case 'Productos por ticket':
        metrics.value = kpisMetrics.value ? kpisMetrics.value : 0
        break
      default:
        metrics.value = kpisMetrics.value ? (
          <Amount
            amount={Number(kpisMetrics.value).toFixed(2)}
            centsSize={'8'}
            amountSize={'15'}
            currecySize={'8'}
          />
        ) : (
          '$ 0.00'
        )
        break
    }
  }
}
