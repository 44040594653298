// Packages
import React, { ReactNode } from 'react'

// Styled Components
import {
  StyledButtonContainer,
  StyledButton,
  StyledButtonIconContainer,
  StyledButtonIcon
} from './styledComponents'

type props = {
  id: string
  name: string
  label?: string
  isActive?: boolean
  className?: string
  dataTestId: string
  type?: string
  shape?: string
  icon?: ReactNode
  isDisabled?: boolean
  onClick?: () => void
  htmlType?: 'button' | 'submit' | 'reset' | undefined
}

const CustomButton: React.FC<props> = ({
  id,
  name,
  label,
  isActive,
  className,
  dataTestId,
  type,
  shape,
  icon,
  isDisabled,
  onClick,
  htmlType
}) => {
  return icon ? (
    <StyledButtonIconContainer>
      <StyledButtonIcon
        id={id}
        name={name}
        className={className}
        data-testid={dataTestId}
        onClick={onClick}
      >
        {icon}
      </StyledButtonIcon>
    </StyledButtonIconContainer>
  ) : (
    <StyledButtonContainer isActive={isActive}>
      <StyledButton
        id={id}
        name={name}
        className={className}
        data-testid={dataTestId}
        type={type}
        shape={shape}
        disabled={isDisabled}
        onClick={onClick}
        htmlType={htmlType}
      >
        {label}
      </StyledButton>
    </StyledButtonContainer>
  )
}

export default CustomButton
