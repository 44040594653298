// Packages
import React from 'react'
import { Select } from 'antd'

// Styled Components
import { StyledSelect } from './styledComponents'

type props = {
  id: string
  dataTestId: string
  className: string
  placeholder: string
  options: any
  keyName: string
  labelName: string
  defaultValue?: string
  handleChange: (optionValue: any) => void
  onFocus?: () => void
}

const SelectComponent: React.FC<props> = ({
  id,
  dataTestId,
  className,
  placeholder,
  options,
  keyName,
  labelName,
  defaultValue,
  handleChange,
  onFocus
}) => {
  const { Option } = Select
  return (
    <StyledSelect
      id={id}
      data-testid={dataTestId}
      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      defaultValue={defaultValue}
    >
      {options?.map((itemOption: any) => {
        return (
          <Option value={itemOption[keyName]} key={itemOption[keyName]}>
            {itemOption[labelName]}
          </Option>
        )
      })}
    </StyledSelect>
  )
}

export default SelectComponent
