// Actions
import { AnyAction } from 'redux'

import { VARIABLES_SUMMARY_SALES } from '../actions'

// Types
interface PayloadDataDasboardSummarySales {
  id: string
  label: string
  isActive: boolean
}
export const setVariablesSumarySales = (
  payload: PayloadDataDasboardSummarySales[]
): AnyAction => ({
  type: VARIABLES_SUMMARY_SALES,
  payload
})
