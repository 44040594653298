/* istanbul ignore file */
// Packages
import React from 'react'
import { Progress } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

// Styled Components
import {
  SelloutSellInContent,
  StyledCardMetricContent,
  StyledMetricCard
} from './styledComponent'
import {
  IndicatorContent,
  StyledIndicatorHeader,
  TextReasignation
} from '../UsablityMetrics/styledComponents'
import UsabiltyPopup, {
  UsabiltyPopupProps
} from '../UsablityMetrics/usabiltyPopup'

// Components

export interface SelloutVsSellinMetricProps {
  sellInValue?: number
  sellOutValue?: number
  percentageSellInOut?: number
  message?: string
  finished?: boolean
  objective?: number
  posUsabilityPopup: boolean
  usabiltyPopupProps: UsabiltyPopupProps
  hiddeUsabilityMetrics: boolean
}

const SelloutVsSellinMetric: React.FC<SelloutVsSellinMetricProps> = ({
  sellInValue,
  sellOutValue,
  percentageSellInOut,
  message,
  finished,
  objective,
  posUsabilityPopup,
  usabiltyPopupProps,
  hiddeUsabilityMetrics
}) => {
  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
  return (
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    <>
      <SelloutSellInContent usage={percentageSellInOut!}>
        <IndicatorContent
          usage={percentageSellInOut!}
          objective={objective!}
          width={70}
        >
          <StyledIndicatorHeader>
            Sell Out vs Sell In{' '}
            <InfoCircleFilled
              onClick={usabiltyPopupProps.setPOSUsabilityPopup}
            />
          </StyledIndicatorHeader>
          {!hiddeUsabilityMetrics ? (
            <Progress percent={percentageSellInOut!} />
          ) : (
            <TextReasignation>
              Tus métricas comenzarán a <br /> mostrarse el siguiente mes
            </TextReasignation>
          )}
        </IndicatorContent>
        <StyledCardMetricContent>
          <StyledMetricCard>
            <small>Sell in</small>
            {!hiddeUsabilityMetrics ? <span>{sellInValue} HI</span> : ''}
          </StyledMetricCard>
          <StyledMetricCard>
            <small>Sell out</small>
            {!hiddeUsabilityMetrics ? <span>{sellOutValue} HI</span> : ''}
          </StyledMetricCard>
        </StyledCardMetricContent>
      </SelloutSellInContent>
      {posUsabilityPopup && (
        <UsabiltyPopup
          {...usabiltyPopupProps}
          message={message}
          finished={finished}
        />
      )}
    </>
  )
}

export default SelloutVsSellinMetric
