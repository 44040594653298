// Packages
import React, { ReactNode } from 'react'
import { Card } from 'antd'

// Styled Components
import {
  StyledCardSalesMetric,
  StyledHeaderCardSalesMetric,
  StyledContentCardSalesMetric,
  StyledSalesMetric,
  StyledKeysMetrics
} from './styledComponents'

// Components
import IconComponent from '../Icon'

// Assets
import TicketIcon from '../../data/assets/icons/ticket.svg'
import TicketHighIcon from '../../data/assets/icons/ticket-high.svg'
import ProductTopIcon from '../../data/assets/icons/product-top.svg'
import ProductTicketIco from '../../data/assets/icons/product-ticket.svg'

// Utils
import { setValueMetrics } from './utils'

export interface KpisMetricsTypes {
  description: string
  name: string
  state: boolean
  value: number | null
}

export interface MetricsTypes {
  label: string
  icon: ReactNode
  value: number | string | any
}

type props = {
  kpisObjects: KpisMetricsTypes[]
  hiddeUsabilityMetrics: boolean
}

const SalesMetrics: React.FC<props> = ({ ...kpisMetrics }) => {
  const listSalesMetrics: MetricsTypes[] = [
    {
      label: 'Ticket promedio',
      icon: <IconComponent icon={TicketIcon} width={21} height={23} />,
      value: 0
    },
    {
      label: 'Ticket más elevado',
      icon: <IconComponent icon={TicketHighIcon} width={21} height={23} />,
      value: 0
    },
    {
      label: 'Producto top',
      icon: <IconComponent icon={ProductTopIcon} width={21} height={23} />,
      value: '-'
    },
    {
      label: 'Productos por ticket',
      icon: <IconComponent icon={ProductTicketIco} width={21} height={23} />,
      value: 0
    }
  ]

  listSalesMetrics.forEach((metrics, idx) => {
    const existData = metrics.label === kpisMetrics.kpisObjects[idx].description
    setValueMetrics(
      metrics,
      kpisMetrics.kpisObjects[idx],
      /* istanbul ignore next */
      kpisMetrics.hiddeUsabilityMetrics ? false : existData
    )
  })

  return (
    <>
      <StyledCardSalesMetric>
        <Card>
          <StyledHeaderCardSalesMetric>
            <h1>Métricas</h1>
          </StyledHeaderCardSalesMetric>
          <StyledContentCardSalesMetric>
            {listSalesMetrics.map((metric, idx: number) => {
              return (
                <StyledSalesMetric
                  key={`sales-metric-${idx}`}
                  isValidData={
                    kpisMetrics.kpisObjects[idx].value !== 0 &&
                    kpisMetrics.kpisObjects[idx].value !== null &&
                    !kpisMetrics.hiddeUsabilityMetrics
                  }
                >
                  <StyledKeysMetrics>
                    {metric.icon}
                    <p>{metric.label}</p>
                  </StyledKeysMetrics>
                  <span>
                    {metric.label === 'Producto top' &&
                    (metric.value === null || metric.value === 'null')
                      ? '-'
                      : metric.value}
                  </span>
                </StyledSalesMetric>
              )
            })}
          </StyledContentCardSalesMetric>
        </Card>
      </StyledCardSalesMetric>
    </>
  )
}

export default SalesMetrics
