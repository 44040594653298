import React from 'react'
import { StyledSelectContainer } from './styledComponents'
import { EnvironmentOutlined } from '@ant-design/icons'
import SelectComponent from '../Select'
import { isEmpty } from 'lodash'

type storesSelecteProps = {
  store: string | undefined
  stores: Array<StoreTypes.stores>
  onChange: (value: string) => void
}

export const StoresSelect: React.FC<storesSelecteProps> = ({
  store,
  stores,
  onChange
}) => {
  return (
    <StyledSelectContainer justify="center" align="middle">
      <EnvironmentOutlined
        style={{
          height: '16px',
          color: '#fff',
          marginRight: '2px'
        }}
      />
      {!isEmpty(stores) && (
        <SelectComponent
          id="select-stores-report"
          dataTestId="data-test-id-select-stores-report"
          className="storeSelectReport"
          placeholder="Selecciona una opción"
          options={stores}
          keyName="storeID"
          labelName="name"
          defaultValue={store}
          handleChange={onChange}
        />
      )}
    </StyledSelectContainer>
  )
}
