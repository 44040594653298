// Packages
import moment from 'moment'

// Types
import { SummarySalesTypes } from './'

export const setVariables = (
  dateId: string,
  sumarySales: SummarySalesTypes,
  week: string,
  month: string
): void => {
  switch (dateId) {
    case 'day':
      sumarySales.setVariablesQuery({
        storeId: sumarySales.store,
        from: `${moment().format('YYYY-MM-DD')}`
      })
      break
    case 'week':
      sumarySales.setVariablesQuery({
        storeId: sumarySales.store,
        from: `${moment(week, 'DD/MM/YYYY').format('YYYY-MM-DD')}`,
        to: `${moment().format('YYYY-MM-DD')}`
      })
      break
    case 'month':
      sumarySales.setVariablesQuery({
        storeId: sumarySales.store,
        from: `${moment(month, 'DD/MM/YYYY').format('YYYY-MM-DD')}`,
        to: `${moment().format('YYYY-MM-DD')}`
      })
      break
    default:
      break
  }
}
