// Packages
import styled from 'styled-components'

interface StyledSalesMetricTypes {
  isValidData: boolean
}

export const StyledCardSalesMetric = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 22px;
  .ant-card {
    width: 90%;
    @media (min-width: 1024px) {
      width: 45%;
    }
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.13);
    .ant-card-body {
      padding: 6px 12px 15px;
    }
  }
`

export const StyledHeaderCardSalesMetric = styled.div`
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
`

export const StyledContentCardSalesMetric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledSalesMetric = styled.div<StyledSalesMetricTypes>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  span {
    max-width: 162px;
    text-align: end;
    font-weight: bold;
    color: ${({ isValidData }) => {
      return isValidData ? '#000' : '#c4c4c4'
    }};
  }
`

export const StyledKeysMetrics = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    transform: translateY(0%);
    margin: 5px 23px 5px 0;
  }
  p {
    font-size: 12px;
    margin: 0;
  }
`
