import React from 'react'
import {
  CloseOutlined,
  InfoCircleOutlined,
  CheckCircleFilled
} from '@ant-design/icons'

import {
  StyledPopup,
  ClosePopup,
  Content,
  Icon,
  Title,
  Label,
  Text
} from './styledComponents'

export interface UsabiltyPopupProps {
  message?: string
  finished?: boolean
  setPOSUsabilityPopup: () => void
  label: string
}

const usabiltyPopup: React.FC<UsabiltyPopupProps> = ({
  message,
  finished,
  setPOSUsabilityPopup,
  label
}) => {
  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
  return (
    <StyledPopup>
      <Content>
        <ClosePopup onClick={setPOSUsabilityPopup}>
          <CloseOutlined />
        </ClosePopup>
        <Icon finished={finished!}>
          {finished ? <CheckCircleFilled /> : <InfoCircleOutlined />}
        </Icon>
        <Title>Usabilidad del POS</Title>
        <Label>{finished ? '¡Felicidades!' : label}</Label>
        <Text>{message}</Text>
      </Content>
    </StyledPopup>
  )
}

export default usabiltyPopup
