import React from 'react'

import {
  StyledContainerSearch,
  StyledCurrency,
  StyledTotal,
  StyledCents
} from './styledComponents'

type props = {
  amount: string
  currecySize: string
  amountSize: string
  centsSize: string
  style?: any
  className?: string
}

const Amount: React.FC<props> = ({
  amount,
  currecySize,
  amountSize,
  centsSize,
  style,
  className
}) => {
  const price = amount.split('.')
  const mount = price[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const cent = price[1]
    ? price[1].length === 1
      ? price[1] + '0'
      : price[1]
    : '00'

  return (
    <StyledContainerSearch style={style}>
      <StyledCurrency fontSize={currecySize} className={className}>
        $
      </StyledCurrency>
      <StyledTotal fontSize={amountSize} className={className}>
        {mount}
      </StyledTotal>
      <StyledCents fontSize={centsSize} className={className}>
        {cent}
      </StyledCents>
    </StyledContainerSearch>
  )
}

export default Amount
