// Actions
import { SAVE_FILTERS, SAVE_DATES, SAVE_STORE } from '../actions'

// Types
import { PayloadSalesReport, PayloadDatesSalesReport } from '../types/index'
import { ActionCreatorTypes } from '../../../reducers/types'

export const saveFilters = (
  payload: PayloadSalesReport
): ActionCreatorTypes => ({
  type: SAVE_FILTERS,
  payload
})
export const saveDates = (
  payload: PayloadDatesSalesReport
): ActionCreatorTypes => ({
  type: SAVE_DATES,
  payload
})
export const saveStore = (payload: string): ActionCreatorTypes => ({
  type: SAVE_STORE,
  payload
})
