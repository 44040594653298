import { Row } from 'antd'
import styled from 'styled-components'

export const StyledSelectContainer = styled(Row)`
  width: 100%;
  min-height: 50px;
  background-color: #001529;
  padding: 0 0 6px 0;
  @media (min-width: 576px) {
    padding: 0;
  }
`
