import { Row } from 'antd'
import styled from 'styled-components'

interface Sizes {
  fontSize: string
  className: string
}

export const StyledContainerSearch = styled(Row)`
  justify-content: center;
  align-items: flex-start;
  margin: 10px 5px;
  width: 100%;
`
export const StyledCurrency = styled.span<Sizes>`
  margin-top: 5px;
  margin-right: 3px;
  font-size: ${(props) => parseInt(props.fontSize)}px;

  @media all and (min-width: 375px) and (max-width: 480px) {
    font-size: ${(props) => (props.className ? parseInt(props.fontSize) : 8)}px;
  }

  @media all and (max-width: 374px) {
    font-size: ${(props) => (props.className ? parseInt(props.fontSize) : 6)}px;
  }
`
export const StyledTotal = styled.span<Sizes>`
  font-size: ${(props) => parseInt(props.fontSize)}px;
  font-weight: bold;
  //color: #1a1a1a;
  @media all and (min-width: 375px) and (max-width: 480px) {
    font-size: ${(props) =>
      props.className ? parseInt(props.fontSize) : 15}px;
  }
  @media all and (max-width: 374px) {
    font-size: ${(props) =>
      props.className ? parseInt(props.fontSize) : 12}px;
  }
`
export const StyledCents = styled.span<Sizes>`
  margin-top: 5px;
  margin-left: 3px;
  font-size: ${(props) => parseInt(props.fontSize)}px;

  @media all and (min-width: 375px) and (max-width: 480px) {
    font-size: 8px;
  }
  @media all and (max-width: 374px) {
    font-size: 6px;
  }
`
