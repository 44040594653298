// Pakages
import { Row } from 'antd'
import styled from 'styled-components'

export const StyledUserDashboard = styled(Row)`
  margin: 14px 0 17px;
  .ant-col {
    span {
      font-size: 13px;
      color: #a0a0a0;
      margin-left: 22px;
    }
    h1 {
      font-size: 20px;
      color: #000;
      font-weight: 500;
      margin-left: 22px;
    }
    img {
      transform: translateY(0%);
      margin-right: 22px;
    }
  }
`
