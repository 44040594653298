// Packages
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'

// Components
import SummarySales from '../../components/Storybook/SummarySales'
import UserDashboard from '../../components/UserDashboard'
import { StoresSelect } from '../../components/StoresSelect'
import Loading from '../../components/Shared/Loading'
import ModalResponse from '../../components/Shared/ModalResponse'
import ModalResult from '../../components/Shared/ModalResult'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import { StyledSubtitle } from './styledComponents'

// Querys
import {
  GET_SALES_METRIC,
  GET_KPIS_METRICS,
  GET_USABILITY_METRICS
} from '../../graphql/queries/dashboard'
import {
  GET_PAYMENT_STATUS_AFFILIATION,
  GET_PAYMENT_AFFILIATION_PROVIDER
} from '../../graphql/queries/paymentAffiliation'

// Types
import { reducersTypes } from '../../redux/reducers'
import IntegrationTypes from '../../types/IntegrationTypes'

// Lodash
import { cloneDeep } from 'lodash'
import UsablityMetrics, {
  UsablityMetricsProps
} from '../../components/UsablityMetrics'
import SelloutVsSellinMetric, {
  SelloutVsSellinMetricProps
} from '../../components/SelloutVsSellinMetric'
import SalesMetrics from '../../components/SalesMetrics'
import { saveStore } from '../../redux/ducks/salesReport/actionsCreators'

// Utils
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/storage'

interface VariablesQueryTypes {
  storeId: string
  from: string
  to?: string
}

type propsResult = {
  isModalVisible: boolean
  content: any
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  content: <></>
}

const DashboardContainer: React.FC = () => {
  const noCache: any = { fetchPolicy: 'no-cache' }
  const { store } = useSelector((state: reducersTypes) => state.sales_report)
  const { user_data } = useSelector((state: reducersTypes) => state.profile)
  const [lastReasignationDate, setLastReasignationDate] = useState()
  const [hiddeUsabilityMetrics, setHiddeUsabilityMetrics] = useState(true)
  const [posUsabilityPopup, setPOSUsabilityPopup] = useState(false)
  const userName = `${user_data?.getProfile?.name} ${user_data?.getProfile?.middleName}`
  const month = moment().subtract(30, 'days').calendar()
  const [variablesQuery, setVariablesQuery] = useState<VariablesQueryTypes>({
    storeId: store ? store : '',
    from: moment(month, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  })
  const [result, setResult] = useState(resultInitialState)

  const handleChangePOSUsabilityPopup = (): void => {
    setPOSUsabilityPopup(!posUsabilityPopup)
  }

  const [
    refetchUsabilityMetrics,
    {
      data: usabilityMetrics,
      loading: loadingUsabilityMetrics,
      error: errorUsabilityMetrics
    }
  ] = useLazyQuery(GET_USABILITY_METRICS)

  const [
    refetchSumarySales,
    { data: salesMetric, loading: loadingSalesMetric }
  ] = useLazyQuery(GET_SALES_METRIC)

  const [
    refecthKpisMetrics,
    { data: kpisMetrics, loading: loadingKapisMetrics }
  ] = useLazyQuery(GET_KPIS_METRICS)

  const { data: paymentProvidersData } =
    useQuery<IntegrationTypes.PaymentProviders>(
      GET_PAYMENT_AFFILIATION_PROVIDER,
      noCache
    )

  const yoloPagoProviderID = paymentProvidersData?.getPaymentProviders.find(
    (provider: any) => provider.Description === 'YoLoPago'
  )?.PaymentProviderID

  const { data: paymentAffiliationStatusData } =
    useQuery<IntegrationTypes.PaymentAffiliationStatus>(
      GET_PAYMENT_STATUS_AFFILIATION,
      noCache
    )

  const yoloPagoAffiliationStatus =
    paymentAffiliationStatusData?.getPaymentAffiliationStatus.find(
      (provider: any) => provider.PaymentProviderID == yoloPagoProviderID
    )?.StatusID || 0

  const yoloPagoAffiliation =
    paymentAffiliationStatusData?.getPaymentAffiliationStatus.find(
      (provider: any) => provider.PaymentProviderID == yoloPagoProviderID
    )

  const parseYoloPagoAffiliation = {
    ...yoloPagoAffiliation,
    MessageProcess: yoloPagoAffiliation?.MessageProcess
      ? JSON.parse(yoloPagoAffiliation?.MessageProcess)
      : { message: '' }
  }

  useEffect(() => {
    if (
      paymentAffiliationStatusData &&
      paymentProvidersData &&
      yoloPagoAffiliationStatus === 3
    ) {
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`success-${Math.random()}`}
            status={'warning'}
            subTitle={
              <>
                <StyledSubtitle style={{ color: '#FF8C07' }}>
                  Registro en YOLOPAGO rechazado
                </StyledSubtitle>
                <StyledSubtitle style={{ color: 'black' }}>
                  {parseYoloPagoAffiliation?.MessageProcess?.message}
                </StyledSubtitle>
              </>
            }
            extra={
              <StyledButtonPrimary
                type="button"
                onClick={handleCancel}
                key={'Accept-create'}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    } else if (
      paymentAffiliationStatusData &&
      paymentProvidersData &&
      yoloPagoAffiliationStatus === 1
    ) {
      const showYPDash = getLocalStorageItem('showYPDash')
      const dateYPApprove = moment(
        parseYoloPagoAffiliation.MessageProcess?.date,
        'DD-MM-YYYY hh:mm:ss'
      )
        .add(1, 'days')
        .valueOf()
      const currentDate = moment().valueOf()

      if (showYPDash === null && currentDate < dateYPApprove) {
        setLocalStorageItem('showYPDash', 'false')
        setResult({
          isModalVisible: true,
          content: (
            <ModalResult
              key={`success-${Math.random()}`}
              status={'success'}
              subTitle={
                <>
                  <StyledSubtitle style={{ color: '#52c41a' }}>
                    Tu registro ha sido aceptado
                  </StyledSubtitle>
                  <StyledSubtitle style={{ color: 'black' }}>
                    Podras comenzar a cobrar con tarjeta dentro de las próximas
                    24 a 48 horas. Si tienes algún problema, comunícate con
                    soporte al WhatsApp 5535784444
                  </StyledSubtitle>
                </>
              }
              extra={
                <StyledButtonPrimary
                  type="button"
                  onClick={handleCancel}
                  key={'Accept-create'}
                >
                  Aceptar
                </StyledButtonPrimary>
              }
            />
          )
        })
      }
    }
  }, [yoloPagoAffiliationStatus])

  const handleCancel = () => {
    setResult(resultInitialState)
    if (yoloPagoAffiliationStatus === 3) {
      navigate('/interaction/yolopago')
    }
  }

  const dispatch = useDispatch()

  const refetchQueries = (variablesQuery: VariablesQueryTypes) => {
    refetchUsabilityMetrics({
      variables: { storeId: variablesQuery.storeId, date: variablesQuery.to },
      context: {
        clientName: 'api-instore'
      },
      fetchPolicy: 'no-cache'
    })
    refetchSumarySales({
      variables: variablesQuery,
      context: {
        clientName: 'api-instore'
      },
      fetchPolicy: 'no-cache'
    })
    refecthKpisMetrics({
      variables: variablesQuery,
      context: {
        clientName: 'api-instore'
      },
      fetchPolicy: 'no-cache'
    })
  }

  useEffect(() => {
    if (store) {
      refetchQueries(variablesQuery)
    }
  }, [store])

  useEffect(() => {
    if (!store && user_data) {
      if (!user_data.getProfile?.stores) {
        return
      }
      const copyVar = cloneDeep(variablesQuery)
      copyVar.storeId = user_data.getProfile?.stores[0].storeID
      setVariablesQuery(copyVar)
      dispatch(saveStore(copyVar.storeId))
      setLastReasignationDate(
        user_data.getProfile?.stores[0].lastReassignationDate
      )
    }
  }, [user_data])

  useEffect(() => {
    if (variablesQuery.storeId) {
      refetchQueries(variablesQuery)
    }
  }, [variablesQuery])

  useEffect(() => {
    if (errorUsabilityMetrics) {
      toast.error('Hubo un error al obtener metricas de usuablidad.')
    }
  }, [errorUsabilityMetrics])

  const usablityMetricsProps: UsablityMetricsProps = {
    posUsabilityPopup: posUsabilityPopup,
    hiddeUsabilityMetrics: hiddeUsabilityMetrics,
    usabiltyPopupProps: {
      setPOSUsabilityPopup: handleChangePOSUsabilityPopup,
      label: 'Empresario'
    },
    progressPercent: usabilityMetrics?.getUsabilityMetric.objective
  }

  const selloutVsSellinMetricProps: SelloutVsSellinMetricProps = {
    posUsabilityPopup: posUsabilityPopup,
    hiddeUsabilityMetrics: hiddeUsabilityMetrics,
    usabiltyPopupProps: {
      setPOSUsabilityPopup: handleChangePOSUsabilityPopup,
      label: 'Empresario'
    }
  }

  useEffect(() => {
    setLastReasignationDate(
      user_data.getProfile?.stores.filter((x) => x.storeID == store)[0]
        ?.lastReassignationDate
    )
    hiddenMetricsValue()
  }, [lastReasignationDate])

  const diffDaysLastReasignationDateAndNextMonth = (
    lastDate: string,
    nowDate: string
  ) => {
    const daysInMonth = moment(nowDate).daysInMonth()
    const nextDateToShow = moment(nowDate, 'YYYY-MM-DD')
      .startOf('month')
      .add(1, 'month')
      .format('YYYY-MM-DD')
    const diffDays = Math.abs(moment(lastDate).diff(nextDateToShow, 'days'))
    return diffDays > daysInMonth
  }

  const hiddenMetricsValue = () => {
    if (lastReasignationDate) {
      const nowDate = moment().format('YYYY-MM-DD')
      const reasignationDate = moment(
        lastReasignationDate,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD')
      const nowMonth = moment(nowDate, 'YYYY-MM-DD').month() + 1
      const nowYear = moment(nowDate).year()
      const diff = Math.abs(moment(reasignationDate).diff(nowDate, 'days'))
      diffDaysLastReasignationDateAndNextMonth(reasignationDate, nowDate)
      setHiddeUsabilityMetrics(
        !diffDaysLastReasignationDateAndNextMonth(reasignationDate, nowDate)
      )
      setVariablesQuery({
        storeId: store ? store : '',
        from: diff <= 28 ? reasignationDate : `${nowYear}-${nowMonth}-01`,
        to: moment().format('YYYY-MM-DD')
      })
    } else {
      setHiddeUsabilityMetrics(false)
    }
  }

  const getStoreId = (): string => {
    if (!store && !user_data.getProfile?.stores) {
      return ''
    }

    const storeId = store ? store : user_data.getProfile?.stores[0].storeID
    return storeId
  }

  return (
    <>
      <StoresSelect
        store={getStoreId()}
        stores={user_data.getProfile?.stores}
        onChange={(storeId) => {
          dispatch(saveStore(storeId))
          const copyVar = cloneDeep(variablesQuery)
          copyVar.storeId = storeId
          setVariablesQuery(copyVar)
          setLastReasignationDate(
            user_data.getProfile?.stores.filter((x) => x.storeID == storeId)[0]
              .lastReassignationDate
          )
        }}
      />
      <ModalResponse
        isModalVisible={result.isModalVisible}
        content={result.content}
        closable={false}
        onCancel={handleCancel}
      />
      {kpisMetrics &&
      !loadingUsabilityMetrics &&
      !loadingSalesMetric &&
      !loadingKapisMetrics ? (
        <>
          <UserDashboard userName={userName} />

          {usabilityMetrics && salesMetric ? (
            <>
              <UsablityMetrics
                {...usabilityMetrics.getUsabilityMetric}
                {...usablityMetricsProps}
              />

              <SelloutVsSellinMetric
                {...usabilityMetrics.getUsabilityMetric}
                {...selloutVsSellinMetricProps}
              />
            </>
          ) : null}

          <SummarySales
            {...salesMetric.getSalesMetric}
            setVariablesQuery={setVariablesQuery}
            store={store}
          />
          <SalesMetrics
            {...kpisMetrics.getKpisMetrics}
            hiddeUsabilityMetrics={hiddeUsabilityMetrics}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default DashboardContainer
