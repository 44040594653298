// Packages
import styled from 'styled-components'
import { Row } from 'antd'

interface StyledFooterCardTypes {
  isEmptyData: boolean
}

export const StyledButtonsSummarySales = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (min-width: 1024px) {
    width: 50%;
  }
`

export const StyledCardContainer = styled.div`
  width: 90%;
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  @media (min-width: 1024px) {
    width: 45%;
  }
  &&& {
    .ant-card .ant-card-body {
      padding: 6px 12px 26px;
    }
  }
`

export const StyledSummarySalesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledHeaderCard = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledTitleCard = styled.h1`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  transform: translateY(6px);
`

export const StyledDatesCard = styled.span`
  font-size: 12px;
`

export const StyledContenCard = styled.div`
  display: flex;
  justify-content: center;
  margin: 22px 0 30px;
  &&& {
    .ant-progress .ant-progress-inner .ant-progress-text {
      font-size: 15px;
      font-weight: 600;
    }
  }
`

export const StyledFooterCard = styled.div<StyledFooterCardTypes>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &&& {
    .ant-card {
      width: 30%;
      border-radius: 6px;
      box-shadow: 0px 2px 11px -5px rgba(138, 138, 138, 1);
      .ant-card-body {
        height: 65px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        color: ${({ isEmptyData }) => (isEmptyData ? '#c4c4c4' : '#000')};
        div {
          font-size: 9px;
          font-weight: 600;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
`

export const StyledButtonLabel = styled.span`
  color: #2680eb;
  margin-right: 8px;
`

export const StyledEmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    transform: translateY(0%);
  }
  span {
    width: 260px;
    text-align: center;
    font-size: 16px;
    margin-top: 12px;
    color: #828282;
    line-height: 1.19;
  }
`
