/* istanbul ignore file */
// Packages
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Col, Row } from 'antd'
import { RightOutlined } from '@ant-design/icons'

// Components
import CustomButton from '../customButton'
import IconComponent from '../../Icon'

// Styyled Components
import {
  StyledButtonsSummarySales,
  StyledCardContainer,
  StyledSummarySalesContainer,
  StyledHeaderCard,
  StyledContenCard,
  StyledFooterCard,
  StyledDatesCard,
  StyledTitleCard,
  StyledButtonLabel,
  StyledEmptyDataContainer
} from './styledComponents'
import moment from 'moment'
import { navigate } from 'gatsby'

// Actions
import { setVariablesSumarySales } from '../../../redux/ducks/dashboard/actionCreators'

// Assets
import EmptyData from '../../../data/assets/icons/empty_data.svg'

// Types
import { reducersTypes } from '../../../redux/reducers'

// Utils
import { setVariables } from './utils'
import { customEventGA } from '../../../utils/googleAnalitycs'

// Lodash
import { cloneDeep } from 'lodash'

// Hooks
import { useConfigFlag } from '../../../hooks/useConfigFlag'
import Amount from '../../Amount'

export interface SummarySalesTypes {
  fromTitle: string
  storeId: string
  toTitle: string
  totalCard: string
  totalCash: string
  totalClubModelorama: string
  totalNumberOperations: string
  totalSales: number
  setVariablesQuery: any
  store: string
}

const SummarySales: React.FC<SummarySalesTypes> = ({ ...sumarySales }) => {
  const from = moment(sumarySales.fromTitle)
    .utc()
    .format('DD-MMM')
    .replace('.', '')
  const to = moment().format('DD-MMM').replace('.', '')
  const cardList = [
    { label: 'Efectivo', value: sumarySales.totalCash },
    { label: 'Club Modelorama', value: sumarySales.totalClubModelorama },
    { label: 'Tarjeta', value: sumarySales.totalCard }
  ]

  const week = `${moment().subtract(7, 'days').calendar()}`

  const { datesSummarySales } = useSelector(
    (state: reducersTypes) => state.dashboard
  )

  const { value: flag } = useConfigFlag('dashboard_button_range_sales')

  const dispatch = useDispatch()

  const handleChangeButtonDate = (dateId: string) => {
    const copyButtonsData = cloneDeep(datesSummarySales)
    copyButtonsData.map((dateCopy: any) => {
      dateCopy.isActive = false
      if (dateCopy.id === dateId) {
        dateCopy.isActive = true
      }
    })
    dispatch(setVariablesSumarySales(copyButtonsData))
    setVariables(dateId, sumarySales, week, month)
  }

  return (
    <StyledSummarySalesContainer>
      <StyledButtonsSummarySales>
        {flag &&
          datesSummarySales.map((date: any, idx: number) => {
            return (
              <CustomButton
                id="button-day-summay-sales"
                name="buttonDaySummarySales"
                dataTestId="button-day-summay-sales"
                className={`button-day-summay-sales`}
                label={date.label}
                key={`buttonDaySummarySales-${idx}`}
                isActive={date.isActive}
                onClick={() => {
                  handleChangeButtonDate(date.id)
                }}
              />
            )
          })}
      </StyledButtonsSummarySales>
      <StyledCardContainer>
        <Card>
          <StyledHeaderCard align="middle">
            <Col>
              <StyledTitleCard>Ventas Totales</StyledTitleCard>
              <StyledDatesCard>{`${from} al ${to}`}</StyledDatesCard>
            </Col>
            <Row>
              <StyledButtonLabel>Ver reporte</StyledButtonLabel>
              <CustomButton
                id="button-go-report-summary-sales"
                name="buttonGoReportSummarySales"
                dataTestId="button-go-report-summary-sales"
                className="button-go-report-summary-sales"
                icon={<RightOutlined />}
                onClick={() => {
                  navigate(`/reports/sales?storeId=${sumarySales.store}`)
                  customEventGA({
                    eventAction: 'button_go_to_sales_report',
                    eventCategory: 'reports',
                    eventLabel: 'dashboard'
                  })
                }}
              />
            </Row>
          </StyledHeaderCard>
          <StyledContenCard>
            {sumarySales.totalSales === null ||
            sumarySales.totalSales === undefined ? (
              <StyledEmptyDataContainer>
                <IconComponent icon={EmptyData} width={91} height={97} />
                <span>
                  Aún no tienes datos registrados, haz ventas con tu POS para
                  comezar a ver tus métricas
                </span>
              </StyledEmptyDataContainer>
            ) : (
              <Amount
                className={'dasboard-styled'}
                amount={Number(sumarySales.totalSales).toString()}
                centsSize={'20'}
                amountSize={'40'}
                currecySize={'20'}
              />
            )}
          </StyledContenCard>
          <StyledFooterCard isEmptyData={sumarySales.totalSales === null}>
            {cardList.map((elem, idx) => (
              <Card key={`${elem.label}-${idx}`}>
                <div>{elem.label}</div>
                <Amount
                  amount={Number(elem.value).toFixed(2)}
                  centsSize={'8'}
                  amountSize={'15'}
                  currecySize={'8'}
                />
              </Card>
            ))}
          </StyledFooterCard>
        </Card>
      </StyledCardContainer>
    </StyledSummarySalesContainer>
  )
}

export default SummarySales
