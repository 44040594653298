// Packages
import { gql } from '@apollo/client'

export const GET_SALES_METRIC = gql`
  query getSalesMetric($storeId: ID!, $from: DateTime!, $to: DateTime) {
    getSalesMetric(storeId: $storeId, from: $from, to: $to) {
      fromTitle
      toTitle
      totalCash
      totalCard
      totalClubModelorama
      totalSales
      totalNumberOperations
      storeId
    }
  }
`

export const GET_KPIS_METRICS = gql`
  query getKpisMetrics($storeId: ID!, $from: DateTime!, $to: DateTime) {
    getKpisMetrics(storeId: $storeId, from: $from, to: $to) {
      kpisObjects {
        description
        value
        state
        name
      }
    }
  }
`
export const GET_USABILITY_METRICS = gql`
  query getUsabilityMetric($storeId: ID!, $date: String!) {
    getUsabilityMetric(storeId: $storeId, date: $date) {
      yearMonthDate
      daysUsedPercent
      daysUsed
      message
      finished
      objective
      sellInValue
      sellOutValue
      percentageSellInOut
    }
  }
`
