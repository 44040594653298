// Packages
import { Button } from 'antd'
import styled from 'styled-components'

interface CustomButtonProps {
  type: any
  shape: any
  onClick: (e: Event) => void
}

interface CustomButtonIconProps {
  name: string
}

interface CustomButtonContainerProps {
  isActive?: boolean
}

export const StyledButtonContainer = styled.div<CustomButtonContainerProps>`
  width: auto;
  .button-accept-filter-sales-report {
    width: 99px;
    margin: 9px;
    font-size: 12px;
    line-height: none;
  }
  .button-clean-filter-sales-report {
    margin-left: 34%;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #0b4793;
    margin: 0;
  }
  .ant-btn-default {
    background-color: #0b4793;
    color: #fff;
  }
  .ant-btn-default[disabled] {
    background-color: #f5f5f5;
  }
  .cancel-button-workers-edit,
  .save-button-workers-edit {
    width: 128px;
    height: 28px;
    border-radius: 100px;
    font-size: 10px;
    font-weight: 600;
    margin-left: 12px;
  }
  .save-button-workers-edit-roles {
    width: 145px;
    height: 48px;
    margin: 22px 43px 0 44px;
    background-color: #0b4793;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  .cancel-button-workers-edit-roles {
    width: 145px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    color: #0b4793;
    background-color: #fff;
    border: none;
  }
  .save-button-sales-report-download-file,
  .cancel-button-sales-report-download-file {
    width: 145px;
    height: 48px;
    font-size: 14px;
    border-radius: 50px;
  }
  .save-button-sales-report-download-file {
    margin: 22px 43px 0 44px;
    background-color: #0b4793;
    margin: 0;
    color: #fff;
  }
  .cancel-button-sales-report-download-file {
    color: #0b4793;
    background-color: #fff;
    border: none;
  }
  .button-day-summay-sales {
    width: 27vw;
    border-radius: 10px;
    background-color: ${({ isActive }) => (isActive ? '#0b4793' : '#ededed')};
    color: ${({ isActive }) => (isActive ? '#fff' : '#5c5c5c')};
    font-size: 14px;
    font-weight: bold;
    @media (min-width: 1024px) {
      width: 10vw;
    }
  }
  .button-go-report-summary-sales {
    background-color: transparent !important;
    color: #2680eb !important;
    border: none;
  }
  .summary-sales-selected {
    background-color: #0b4793;
    color: #fff;
  }
`

export const StyledButton = styled(Button)<CustomButtonProps>`
  width: 100%;
`

export const StyledButtonIcon = styled.div<CustomButtonIconProps>`
  width: auto;
`

export const StyledButtonIconContainer = styled.div`
  width: auto;
  .button-icon-back-operators,
  .button-icon-back-sales-report,
  .button-icon-back-month-sales-report {
    margin: 0 5px;
    stroke: black;
    stroke-width: 5%;
  }
  .button-icon-back-sales-report-date-picker {
    margin-right: 8px;
  }
  .button-go-report-summary-sales {
    color: #2680eb;
  }
`
