// Packages
import { Select } from 'antd'
import styled from 'styled-components'

export const StyledSelect = styled(Select)`
  width: 80%;
  margin-left: 9px !important;
  @media (min-width: 677px) {
    width: 88%;
  }
  .ant-select-selector {
    background-color: #001529 !important;
    border: none !important;
    color: #fff;
  }
  .ant-select-arrow {
    color: #fff;
  }
`
