// Packages
import React from 'react'

// Components
import LayoutMain from '../../layout'
import SearchEngineOptimization from '../../components/SEO'
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'

// Container
import DashboardContainer from '../../containers/DashboardContainer'

const DashboardPage: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Dashboard" />
      <WithPrivateRoute component={DashboardContainer} />
    </LayoutMain>
  )
}

export default DashboardPage
